import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <div className="navbar-desktop">
        <div className="navbar-main">
          <div className="navbar-branding">
            <Link to="/" className="navbar-navlink">
              <img
                alt={props.imagealt1}
                src={props.imagesrc1}
                className="navbar-lighting"
              />
            </Link>
          </div>
        </div>
        <div className="navbar-links">
          <a href="#new" className="navbar-link link">
            {props.link2}
          </a>
          <a href="#platform" className="navbar-link1 link">
            {props.link5}
          </a>
          <a href="#feature" className="navbar-link2 link">
            {props.link4}
          </a>
          <a href="#join" className="navbar-link3 link">
            {props.link51}
          </a>
        </div>
        <div className="navbar-quick-actions">
          <svg viewBox="0 0 1024 1024" className="navbar-icon">
            <path
              d="M512 0c-282.77 0-512 230.796-512 515.5s229.23 515.5 512 515.5 512-230.796 512-515.5-229.23-515.5-512-515.5zM288 672c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c0 88.366-71.634 160-160 160zM736 672c-88.368 0-160-71.634-160-160s71.632-160 160-160 160 71.634 160 160c0 88.366-71.632 160-160 160z"
              className=""
            ></path>
          </svg>
          <svg viewBox="0 0 1024 1024" className="navbar-icon2">
            <path
              d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
              className=""
            ></path>
          </svg>
          <img
            id="open-mobile-menu"
            alt={props.pastedImageAlt}
            src={props.pastedImageSrc}
            className="navbar-hamburger-menu"
          />
        </div>
      </div>
      <div id="mobile-menu" className="navbar-mobile">
        <div className="navbar-top">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="navbar-image"
          />
          <svg
            id="close-mobile-menu"
            viewBox="0 0 1024 1024"
            className="navbar-icon4"
          >
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              className=""
            ></path>
          </svg>
        </div>
        <div className="navbar-links1">
          <Link to="/" className="navbar-navlink1">
            {props.text1}
          </Link>
          <Link to="/" className="navbar-navlink2">
            {props.text11}
          </Link>
          <Link to="/" className="navbar-navlink3">
            {props.text12}
          </Link>
          <Link to="/" className="navbar-navlink4">
            {props.text13}
          </Link>
          <div className="navbar-buttons">
            <Link to="/" className="navbar-navlink5">
              <div className="navbar-btn">
                <span className="navbar-text">{props.text131}</span>
              </div>
            </Link>
            <Link to="/" className="navbar-navlink6">
              <div className="navbar-btn1">
                <span className="navbar-text1">{props.text1311}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className="navbar-container1">
          <Script
            html={` <script>
    /*
    Mobile menu - Code Embed
    */

    // Mobile menu
    const mobileMenu = document.querySelector("#mobile-menu");

    // Buttons
    const closeButton = document.querySelector("#close-mobile-menu");
    const openButton = document.querySelector("#open-mobile-menu");

    if (mobileMenu && closeButton && openButton) {
      // On openButton click, set the mobileMenu position left to -100vw
      openButton.addEventListener("click", function () {
        mobileMenu.style.transform = "translateX(0%)";
      });

      // On closeButton click, set the mobileMenu position to 0vw
      closeButton.addEventListener("click", function () {
        mobileMenu.style.transform = "translateX(100%)";
      });
    }
  </script>`}
            className=""
          ></Script>
        </div>
      </div>
    </nav>
  )
}

Navbar.defaultProps = {
  link5: '平台介紹',
  imagealt: 'image',
  text1: 'Features',
  text12: 'Prices',
  imagealt1: 'image',
  link4: '創業機會',
  brandingSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4a46e3f6-a2fb-4002-a0ce-bb246bd8698d/afed3d97-066a-440a-a27e-491fdc7ac2b5?org_if_sml=15413',
  rootClassName: '',
  link51: '加入我們',
  link1: '關於我們',
  text131: 'Log in',
  imageAlt: 'image',
  pastedImageSrc: '/pastedimage-8o98.svg',
  text1311: 'Sign up',
  text13: 'Contact',
  imagesrc1: '/logo-200h.png',
  link2: '最新消息',
  pastedImageAlt: 'pastedImage',
  imageSrc: '/pastedimage-cx4wqj.svg',
  imagesrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  brandingAlt: 'pastedImage',
  text11: 'How it works',
}

Navbar.propTypes = {
  link5: PropTypes.string,
  imagealt: PropTypes.string,
  text1: PropTypes.string,
  text12: PropTypes.string,
  imagealt1: PropTypes.string,
  link4: PropTypes.string,
  brandingSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  link51: PropTypes.string,
  link1: PropTypes.string,
  text131: PropTypes.string,
  imageAlt: PropTypes.string,
  pastedImageSrc: PropTypes.string,
  text1311: PropTypes.string,
  text13: PropTypes.string,
  imagesrc1: PropTypes.string,
  link2: PropTypes.string,
  pastedImageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imagesrc: PropTypes.string,
  brandingAlt: PropTypes.string,
  text11: PropTypes.string,
}

export default Navbar
