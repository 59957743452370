import React from 'react'

import PropTypes from 'prop-types'

import './accordion.css'

const Accordion = (props) => {
  return (
    <div className={`accordion-accordion ${props.rootClassName} `}>
      <div data-role="accordion-container" className="accordion-element">
        <div className="accordion-container">
          <img
            alt={props.imagealt}
            src={props.imagesrc}
            className="accordion-image"
          />
          <div className="accordion-details">
            <span className="accordion-text">{props.text1}</span>
            <span data-role="accordion-content" className="accordion-text1">
              {props.text11}
            </span>
            <span data-role="accordion-content" className="accordion-text2">
              {props.date1}
            </span>
          </div>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="accordion-element1 accordion-element"
      >
        <div className="accordion-container1">
          <img
            alt={props.imagealt2}
            src={props.imagesrc2}
            className="accordion-image1"
          />
          <div className="accordion-details1">
            <span className="accordion-text3">{props.text21}</span>
            <span data-role="accordion-content" className="accordion-text4">
              {props.description2}
            </span>
            <span data-role="accordion-content" className="accordion-text5">
              {props.date2}
            </span>
          </div>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="accordion-element2 accordion-element"
      >
        <div className="accordion-container2">
          <img
            alt={props.image3}
            src={props.imagesrc3}
            className="accordion-image2"
          />
          <div className="accordion-details2">
            <span className="accordion-text6">{props.text31}</span>
            <span data-role="accordion-content" className="accordion-text7">
              {props.description3}
            </span>
            <span data-role="accordion-content" className="accordion-text8">
              {props.date3}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  text31: 'Aliquam quaerat voluptatem',
  date1: '2023/12/10',
  date3: '2023/12/10',
  text21: 'Aliquam quaerat voluptatem',
  text11:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
  rootClassName: '',
  date12: '2023/12/10',
  imagealt: 'image',
  description1: '2023/12/10',
  text3:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
  description2:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
  imagesrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  text1: 'Aliquam quaerat voluptatem',
  text5:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
  text2: 'Nemo enim ipsam voluptatem quia voluptas',
  imagealt2: 'image',
  imagesrc3: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image3: 'image',
  description3:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
  date2: '2023/12/10',
  imagesrc2: 'https://play.teleporthq.io/static/svg/default-img.svg',
  text4: 'Magnam aliquam quaerat voluptatem',
}

Accordion.propTypes = {
  text31: PropTypes.string,
  date1: PropTypes.string,
  date3: PropTypes.string,
  text21: PropTypes.string,
  text11: PropTypes.string,
  rootClassName: PropTypes.string,
  date12: PropTypes.string,
  imagealt: PropTypes.string,
  description1: PropTypes.string,
  text3: PropTypes.string,
  description2: PropTypes.string,
  imagesrc: PropTypes.string,
  text1: PropTypes.string,
  text5: PropTypes.string,
  text2: PropTypes.string,
  imagealt2: PropTypes.string,
  imagesrc3: PropTypes.string,
  image3: PropTypes.string,
  description3: PropTypes.string,
  date2: PropTypes.string,
  imagesrc2: PropTypes.string,
  text4: PropTypes.string,
}

export default Accordion
