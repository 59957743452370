import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Platform from '../components/platform'
import Feature from '../components/feature'
import Join from '../components/join'
import Accordion from '../components/accordion'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Lightning</title>
        <meta name="description" content="Lightning Startup" />
        <meta property="og:title" content="Lightning" />
        <meta property="og:description" content="Lightning Startup" />
      </Helmet>
      <div className="home-header">
        <div className="home-heading">
          <Navbar rootClassName="navbar-root-class-name"></Navbar>
        </div>
        <div id="hero" className="home-hero">
          <div className="home-content">
            <div className="home-video-container">
              <video
                src="https://file.numiner.io/0125.mp4"
                loop
                muted
                poster="/video-700h.jpg"
                preload="auto"
                autoPlay
                controls="true"
                className="home-video"
              ></video>
            </div>
          </div>
        </div>
      </div>
      <div id="platform" className="home-platform">
        <h2 className="home-text">
          <span className="home-text01">平台介紹</span>
          <br></br>
          <br></br>
        </h2>
        <div className="home-platform-list">
          <Platform
            title="平台使命"
            thumbnail="/images/platform-1-1500w.png"
            description="透過創新的直播帶貨數字人營銷平台，細心打造一個獨一無二的購物體驗，旨在實現加盟經營者和消費者的共同成長與成功。"
            rootClassName="platform-root-class-name"
          ></Platform>
          <Platform
            title="平台價值"
            thumbnail="/images/platform-2-1500w.png"
            description="在瞬息萬變的商業環境中，企業價值觀是我們智慧與行動的指南，更是實現共同目標的堅實基石。"
            rootClassName="platform-root-class-name1"
          ></Platform>
          <Platform
            title="創新與未來發展"
            thumbnail="/images/platform-3-1500w.png"
            description="我們以創新為核心，致力於建立一個先進的直播帶貨平台，結合多項技術和策略，為用戶提供卓越的購物體驗。"
            rootClassName="platform-root-class-name2"
          ></Platform>
          <Platform
            title="什麼是閃電創業"
            thumbnail="/images/platform-4-1500w.png"
            description="透過創新的直播帶貨數字人營銷平台，細心打造一個獨一無二的購物體驗，旨在實現加盟經營者和消費者的共同成長與成功。"
            rootClassName="platform-root-class-name3"
          ></Platform>
        </div>
        <div className="home-header1">
          <div className="home-heading1"></div>
        </div>
      </div>
      <div id="feature" className="home-features">
        <h2 className="home-text04">創業機會</h2>
        <div className="home-feature-list">
          <Feature
            title="獨家合作品牌"
            thumbnail="/images/icon01-1500w.png"
            description="與知名品牌建立獨家合作，確保商品獨特性和高品質，為您提供卓越的購物體驗。"
            rootClassName="feature-root-class-name2"
          ></Feature>
          <Feature
            title="定期商品更新"
            thumbnail="/images/icon02-1500w.png"
            description="我們定期引入新品和季節性商品，確保平台商品始終保持新鮮感，吸引您的興趣。"
          ></Feature>
          <Feature
            title="提供的供應篩選篩選"
            thumbnail="/images/icon03-1500w.png"
            description="我們實施嚴格的供應商篩選標準，確保合作廠商具備良好信譽和高品質生產能力。"
          ></Feature>
          <Feature
            title="品質標準和認證"
            thumbnail="/images/icon04-1500w.png"
            description="所有商品符合相應的品質標準和認證，保障您的權益，提供高品質的商品。"
          ></Feature>
          <Feature
            title="商品分類和標籤"
            thumbnail="/images/icon05-1500w.png"
            description="提供清晰的商品分類和標籤，使您輕鬆找到所需商品，提高購物的便利性。"
          ></Feature>
          <Feature
            title="專業測試和評估"
            thumbnail="/images/icon06-1500w.png"
            description="利用先進的AI數字人技術，我們的虛擬主播將全天候在蝦皮直播"
          ></Feature>
          <Feature
            title="用戶評價和評論"
            thumbnail="/images/icon07-1500w.png"
            description="提供用戶評價和評論功能，幫助您做出明智的購買決定，增加透明度。"
          ></Feature>
          <Feature
            title="資訊透明度"
            thumbnail="/images/icon08-1500w.png"
            description="提供商品的詳細資訊，包括規格、材料、生產地等，讓您充分了解所購商品。"
          ></Feature>
          <Feature
            title="退換貨政策"
            thumbnail="/images/icon09-1500w.png"
            description="制定明確的退換貨政策，保障您在購物過程中的權益，提供安心的購物環境。"
            rootClassName="feature-root-class-name3"
          ></Feature>
          <Feature
            title="可追溯的產品來源"
            thumbnail="/images/icon10-1500w.png"
            description="提供商品的可追溯性，讓您了解商品的生產來源和製造過程。"
          ></Feature>
          <Feature
            title="品牌合作活動"
            thumbnail="/images/icon11-1500w.png"
            description="與優質品牌合作，推出獨家或限量版商品，帶給您獨特的購物體驗。"
          ></Feature>
          <Feature
            title="即時庫存狀態"
            thumbnail="/images/icon12-1500w.png"
            description="提供即時的庫存狀態，確保您了解商品的供應狀況，提高購物的滿意度。"
          ></Feature>
        </div>
        <div className="home-header2">
          <div className="home-heading2"></div>
        </div>
      </div>
      <div id="join" className="home-join">
        <div className="home-section">
          <div className="home-content1">
            <div className="home-heading3">
              <h3 className="home-text05">如何加入閃電創業包？</h3>
            </div>
            <div className="home-container1">
              <div className="home-content2">
                <div className="home-points">
                  <Join
                    title="營銷能力"
                    description="具有一定的營銷和經營管理能力，能夠有效推動網購市場。"
                    rootClassName="join-root-class-name"
                  ></Join>
                  <Join
                    title="品牌認同"
                    description="對品牌理念和產品有認同感，願意全力以赴推動品牌。"
                    rootClassName="join-root-class-name1"
                  ></Join>
                  <Join
                    title="合法資格"
                    description="具有合法經營資格，能夠遵守當地的法律法規。"
                    rootClassName="join-root-class-name2"
                  ></Join>
                  <div className="home-container2">
                    <Link to="/" className="home-navlink">
                      <div className="home-get-started">
                        <span className="home-sign-up">
                          立即加入
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="home-container3">
                <div className="home-image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="new" className="home-news">
        <div className="home-section1">
          <h2 className="home-text06">
            <span>最新消息</span>
            <br></br>
          </h2>
          <div className="home-content3">
            <Accordion
              text1="24小時AI直播，銷售不間斷"
              text11="我們為您提供超過20,000種熱銷商品，您可以選擇最適合您市場定位的商品。利用先進的AI數字人技術，我們的虛擬主播將全天候在蝦皮直播，為您帶來源源不斷的客流和銷售。"
              text21="24小時AI直播，銷售不間斷"
              text31="24小時AI直播，銷售不間斷"
              imagesrc="/images/news-200h.png"
              imagesrc2="/images/news2-200h.png"
              imagesrc3="/images/news-200h.png"
              description2="我們為您提供超過20,000種熱銷商品，您可以選擇最適合您市場定位的商品。利用先進的AI數字人技術，我們的虛擬主播將全天候在蝦皮直播，為您帶來源源不斷的客流和銷售。"
              description3="我們為您提供超過20,000種熱銷商品，您可以選擇最適合您市場定位的商品。利用先進的AI數字人技術，我們的虛擬主播將全天候在蝦皮直播，為您帶來源源不斷的客流和銷售。"
              rootClassName="accordion-root-class-name"
            ></Accordion>
          </div>
          <span className="home-text09">
            <span className="home-text10">查看更多 &gt;</span>
            <br></br>
          </span>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
