import React from 'react'

import PropTypes from 'prop-types'

import './join.css'

const Join = (props) => {
  return (
    <div className={`join-feature ${props.rootClassName} `}>
      <div className="join-content">
        <span className="join-title">{props.title}</span>
        <span className="join-description">{props.description}</span>
      </div>
    </div>
  )
}

Join.defaultProps = {
  thumbnailAlt: 'image',
  thumbnail: 'e3820093-8ee0-4e89-866a-64bf98c66c9b',
  description:
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa',
  rootClassName: '',
  title: 'Advantage',
}

Join.propTypes = {
  thumbnailAlt: PropTypes.string,
  thumbnail: PropTypes.string,
  description: PropTypes.string,
  rootClassName: PropTypes.string,
  title: PropTypes.string,
}

export default Join
