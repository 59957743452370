import React from 'react'

import PropTypes from 'prop-types'

import './platform.css'

const Platform = (props) => {
  return (
    <div className={`platform-feature ${props.rootClassName} `}>
      <img
        alt={props.thumbnailAlt}
        src={props.thumbnail}
        className="platform-image"
      />
      <div className="platform-content">
        <span className="platform-title">{props.title}</span>
        <span className="platform-description">{props.description}</span>
      </div>
    </div>
  )
}

Platform.defaultProps = {
  thumbnailAlt: 'image',
  title: 'Account',
  description:
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa',
  thumbnail: '/vector.svg',
  rootClassName: '',
}

Platform.propTypes = {
  thumbnailAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Platform
