import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-footer">
      <div className="footer-content">
        <div className="footer-information">
          <div className="footer-heading">
            <img
              alt={props.pastedImageAlt}
              src={props.pastedImageSrc}
              className="footer-pasted-image"
            />
            <span className="footer-text">{props.time}</span>
            <span className="footer-text1">{props.copyright}</span>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <span className="footer-header">{props.header}</span>
            <span className="footer-link">{props.link}</span>
            <span className="footer-link1">{props.link1}</span>
            <span className="footer-link2">{props.link2}</span>
            <span className="footer-link3">{props.link3}</span>
          </div>
          <div className="footer-column1">
            <span className="footer-header1">{props.header4}</span>
            <span className="footer-link4">{props.link15}</span>
            <span className="footer-link5">{props.link16}</span>
            <span className="footer-link6">{props.link23}</span>
            <span className="footer-link7">{props.link33}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  link9: 'Careers',
  link13: '特色總覽',
  link14: '功能介紹',
  link22: '特色總覽',
  link1: '功能介紹',
  link31: '功能介紹',
  link: '特色總覽',
  link33: '功能介紹',
  link15: '特色總覽',
  link8: 'Partners',
  link3: '功能介紹',
  link10: 'Press & Media',
  time: '服務時間 星期一至五 上午 10 點至晚上 7 點',
  header4: '我要開店',
  link21: '特色總覽',
  header1: 'Company',
  pastedImageSrc: '/logo-200h.png',
  pastedImageAlt: 'pastedImage',
  copyright: '© Copyright 2024 LIGHTING Tech Co., Ltd.',
  link7: 'News',
  link12: '功能介紹',
  link2: '特色總覽',
  header3: '更多資源',
  link5: 'About',
  link32: '功能介紹',
  text: '© 2022 finbest. All Rights Reserved.',
  link16: '功能介紹',
  header2: '我要開店',
  link11: '特色總覽',
  header: '社群購物',
  link23: '特色總覽',
  link6: 'Team',
  link4: 'Static Website Generator',
}

Footer.propTypes = {
  link9: PropTypes.string,
  link13: PropTypes.string,
  link14: PropTypes.string,
  link22: PropTypes.string,
  link1: PropTypes.string,
  link31: PropTypes.string,
  link: PropTypes.string,
  link33: PropTypes.string,
  link15: PropTypes.string,
  link8: PropTypes.string,
  link3: PropTypes.string,
  link10: PropTypes.string,
  time: PropTypes.string,
  header4: PropTypes.string,
  link21: PropTypes.string,
  header1: PropTypes.string,
  pastedImageSrc: PropTypes.string,
  pastedImageAlt: PropTypes.string,
  copyright: PropTypes.string,
  link7: PropTypes.string,
  link12: PropTypes.string,
  link2: PropTypes.string,
  header3: PropTypes.string,
  link5: PropTypes.string,
  link32: PropTypes.string,
  text: PropTypes.string,
  link16: PropTypes.string,
  header2: PropTypes.string,
  link11: PropTypes.string,
  header: PropTypes.string,
  link23: PropTypes.string,
  link6: PropTypes.string,
  link4: PropTypes.string,
}

export default Footer
